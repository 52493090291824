import React from 'react'
import { Link } from "gatsby"

const PostLink = ({ post }) => (
    <div className="grid-item">
        <div className="grid-item__left">
          <Link to={post.frontmatter.path}>
            <div className="date">
              {post.frontmatter.date}
            </div>
            </Link>
            <div className="tags">
              {post.frontmatter.tags}
            </div>
        </div>
        
        <div className="grid-item__right">
          <Link to={post.frontmatter.path} className="remove_a">
          <h3 className="heading">
            {post.frontmatter.title}
          </h3>
          </Link>
        </div>
        
      
    </div>
  )

export default PostLink