import React from 'react'
import Header from '../components/header';
import Helmet from "react-helmet"; 
import PostLink from "../components/post-link";

import '../styles/blog.css'

export default function blog(
    { 
        data: {
            allMdx: {
                edges
            }
        } 
    }) 
{
    const Posts = edges.map(edge => <PostLink key={edge.node.id} post={edge.node}/>)
    return (
        <div>
            <Helmet title="Anson's Blog" />
            <Header />
            <main className="content">
                <h1>Musings</h1>
                <h6 id="underheading">Articles, Snippets, Thoughts, and everything else!</h6> 

                <div className="grid-container">
                    {Posts}
                </div>
            </main>
        </div>
    )
}

export const query = graphql `
    query {
        allMdx(sort: { order: DESC, fields: [frontmatter___date] }) {
        edges {
            node {
            id
            frontmatter {
                date(formatString: "MMMM DD, YYYY")
                path
                title
                tags
            }
            }
        }
        }
    }
`